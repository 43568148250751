var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "d-flex justify-end sticky to-header"
  }, [_vm.hasRight('DEVICE_WRITE') ? _c('v-btn', _vm._b({
    attrs: {
      "to": {
        name: 'NewBarrier'
      },
      "title": "Hozzáadás"
    }
  }, 'v-btn', _vm.$config.buttonAttrs.floating, false), [_c('v-icon', {
    attrs: {
      "dark": ""
    }
  }, [_vm._v("mdi-plus")])], 1) : _vm._e()], 1), _c('h1', {
    staticClass: "py-6"
  }, [_vm._v("Sorompók")]), _c('title-row', {
    attrs: {
      "title": "Sorompók"
    },
    scopedSlots: _vm._u([{
      key: "buttons",
      fn: function () {
        return [_c('title-row-button', {
          attrs: {
            "to": {
              name: 'NewCamera'
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-plus")]), _vm._v(" Új ")], 1)];
      },
      proxy: true
    }])
  }), _c('v-expansion-panels', {
    staticClass: "mb-4",
    attrs: {
      "mandatory": !!Object.values(_vm.search).find(function (e) {
        return e !== '';
      })
    }
  }, [_c('v-expansion-panel', [_c('v-expansion-panel-header', [_c('span', [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-filter")]), _vm._v(" Szűrés ")], 1)]), _c('v-expansion-panel-content', [_c('v-row', {
    staticClass: "mt-2"
  }, [_c('v-col', {
    attrs: {
      "cols": 12,
      "sm": 6,
      "md": 3
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Megnevezés",
      "clearable": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.searchEventHandler
    },
    model: {
      value: _vm.search.name,
      callback: function ($$v) {
        _vm.$set(_vm.search, "name", $$v);
      },
      expression: "search.name"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": 12,
      "sm": 6,
      "md": 3
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "label": "Kontroller",
      "items": _vm.controllers,
      "item-text": "name",
      "item-value": "element_id",
      "hide-details": "",
      "clearable": "",
      "value-comparator": _vm.$config.comparators.isEqual
    },
    on: {
      "change": _vm.searchEventHandler
    },
    model: {
      value: _vm.search.controller_id,
      callback: function ($$v) {
        _vm.$set(_vm.search, "controller_id", $$v);
      },
      expression: "search.controller_id"
    }
  })], 1)], 1)], 1)], 1)], 1), _c('v-card', [_c('v-data-table', {
    attrs: {
      "loading": _vm.loading,
      "headers": _vm.dataTable.headers,
      "items": _vm.dataTable.items,
      "server-items-length": _vm.dataTable.itemsLength,
      "options": _vm.dataTable.options,
      "footer-props": _vm.$config.dataTableFooterProps,
      "calculate-widths": ""
    },
    on: {
      "update:options": function ($event) {
        return _vm.$set(_vm.dataTable, "options", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: `item.k`,
      fn: function (_ref) {
        var item = _ref.item;
        return [_vm._v(_vm._s(item.x_coord) + ", " + _vm._s(item.y_coord))];
      }
    }, {
      key: `item.actions`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_vm.hasRight('DEVICE_WRITE') ? _c('v-btn', {
          staticClass: "rounded-sm",
          attrs: {
            "fab": "",
            "small": "",
            "color": "primary",
            "dark": "",
            "text": "",
            "to": {
              name: 'BarrierEditor',
              params: {
                id: item.element_id
              }
            },
            "title": "Módosítás"
          }
        }, [_c('v-icon', [_vm._v("mdi-pencil")])], 1) : _vm._e(), _vm.hasRight('DEVICE_WRITE') ? _c('v-btn', {
          staticClass: "rounded-sm",
          attrs: {
            "fab": "",
            "small": "",
            "color": "primary",
            "dark": "",
            "text": "",
            "title": "Törlés"
          },
          on: {
            "click": function ($event) {
              return _vm.deleteBarrier(item);
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-delete")])], 1) : _vm._e()];
      }
    }], null, true)
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }